
import * as Components from "./Hayats"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "hayats"
}
