import React from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'

const images = [
  "https://afag.imgix.net/hayats-kitchen/4-piece-quails.jpg?w=800&auto=format",
  "https://afag.imgix.net/hayats-kitchen/kibbie-nayeh.jpg?w=800&auto=format",
  "https://afag.imgix.net/hayats-kitchen/4-piece-sanbousik.jpg?w=800&auto=format",
  "https://afag.imgix.net/hayats-kitchen/mujadara.jpg?w=800&auto=format",
  "https://afag.imgix.net/hayats-kitchen/tabouli-salad.jpg?w=800&auto=format",
  "https://afag.imgix.net/hayats-kitchen/garlic-wings.jpg?w=800&auto=format",
  "https://afag.imgix.net/hayats-kitchen/soujouk-sandwich-1.jpg?w=800&auto=format"
]

export default function () {
  return(
    <div className="gallery-page">
      {/* <div className="top-banner" style={{backgroundImage: "url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/ure9ymqsporphmwvvitt)"}}>
        <h1>Catering</h1>
      </div> */}

      <div className="page-title">
        <h1>Gallery</h1>
      </div>

      <div className="photos">
        {images.map((url) => {
          return(
            <div className="photo-wrapper">
              <img src={url} alt=""/>
            </div>
          )
        })}
      </div>

      <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Order Online</Link>
      </div>
    </div>
  )
}
