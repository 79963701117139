import React from 'react'


export default function () {
  return(
    <div
      style={{
        marginTop: "2em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
      className="powered-by">

      <div
        className="text">

        {/* <p
          style={{
            display: "flex",
            alignItems: "center"
          }}
          >powered by <img
          style={{ width: "25px", margin: "0 0.2em" }}
          src="https://afag.imgix.net/hayats-kitchen/square-1.png?w=50" alt=""/>
          Square
        </p> */}
      </div>
    </div>
  )
}
