import React from 'react'


export default function () {
  return(
    <div className="contact-page">
      {/* <div className="top-banner" style={{backgroundImage: "url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/ure9ymqsporphmwvvitt)"}}>
        <h1>Catering</h1>
      </div> */}


      <div className="page-title">
      <h1>Contact</h1>
    </div>

    <ul className="content">
      <li>Hours: <br/>Mon - Sun : 11:00 am - 10:00 pm</li>
      <li>Address: <br/>11009 Burbank Blvd<br /> Ste 117,<br />
North Hollywood, CA 91601</li>
      <li>
        Email: <a href="mailto:hsshatila@yahoo.com">hsshatila@yahoo.com</a>
      </li>

      <li>
        Phone: <a href="tel:+18187614656">(818) 761-4656</a>
      </li>
    </ul>
    </div>
  )
}
